import React from "react";
import Layout from "../components/Layout";
import "../styles/home.scss";
import routes from "../components/constants/routes";
import { Link } from "gatsby";

export default function NotFound() {
  return (
    <Layout>
      <div className="container">
        <div className="not-found row">
          <div className="col-md-12">
            <div className="not-found-body">
              <img src="/404.svg" />
              <div className="home-btn">
                <Link className="" to={routes.About}>
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
